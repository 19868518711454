/*  General */

.App {
  font-color:white;
  background-color: black;
  padding-top: 70px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #fff;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}








/*  Nav */

.nav {
	padding-top:40px;
    background-color:#020202;
     /* padding-bottom:30px;*/
      position: fixed;
	  font-size: 15px;
      z-index: 99;
      top: 0;
      width: 100%;
      color:#fff !important;
      text-transform:uppercase;
      letter-spacing: .8px;
}

.nav-inner{
	
	border-bottom: #fff solid 1px;
    padding-bottom: 10px;
    margin-bottom: -1px;
}

.navleft{
	z-index: 1;
	text-align: left;
	text-transform: uppercase;
	letter-spacing: .8px;
}

	.navleft a{
		color:#fff;
		font-family: 'Sans',Helvetica;
		opacity:1;
		text-decoration: none;
	}

	.navleft a:hover{
		-webkit-transition: all .2s linear;
		opacity: .3;
	}


.navright{
	text-align:right;
}


	.navright a{
		padding-left: 0px;
		font-family: 'Sans', Helvetica;
		color:#fff;
		opacity:1;

	}

	.navright a:hover{
		-webkit-transition: all .2s linear;
		opacity: .3;
	}



.spacer-white {
	margin-top: 10px;
    background-color: #fff;
    height: 2px;

    }

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
}


/*  HP */

.rollover{
	background:rgba(216,177,255,1);
	line-height: 0px;
	border-radius: 0px;

}


.item {
	/*text-align: left;
	color:#fff;
	padding-top: 10px;
	text-transform: uppercase;
	letter-spacing: .8px;*/
	opacity:1;

  }

.rollover:hover .item {
     transition: all 0.2s ease;
	opacity: 0;
}


.homepage-image{
	

}



.roll {
    position:relative;
    overflow:hidden;
    max-width: 100%;
	display: block;
	}


.o3{
    position:absolute;
    top:0%;
    left:0;
    width: 100%;
    height: 100%;
    background:rgba(216,177,255,0);
    transition:0.2s ease;
	-webkit-transform: translateZ(0);
}

.roll:hover .o3 {
    background:rgba(216,177,255,1);

}

img{
	max-width: 100% !important;
	border-radius: 0px;
}

img.homepage-image{
	opacity:1;
}

/*img.homepage-image:hover{
	opacity:.3;
    transition: 0.15s ease;

}
*/








/*  Project */

.top{
padding-bottom: 24px;
}

.wrapper{
  padding-top: 30px;
}

.project{
	padding-top: 50px;
	padding-bottom: 70px;

}

.project-section{
	padding-top: 0;
	padding-bottom: 100px;
	justify-content: center;
	display: flex;
	flex: auto;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.project-section-bg{
	padding-top: 100px;
	padding-bottom: 100px;
	justify-content: center;
	display: flex;
	flex: auto;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.diptych-left{
	padding-left: 15px;
	padding-right: 0px;
}

.diptych-left img{
	border-radius: 0px;
}

.diptych-right{
	padding-left: 0px;
	padding-right: 15px;
}

.diptych-right img{
	border-radius: 0px;
}

.cover{
	margin-top: -100px;
	width: 100%;
	height: 100vh;
	object-fit: cover;
}

img.cover {
	border-radius: 0px;
}

.reg{
	 max-width: 100% !important;
}


.full-width{
	height: 720px;
    overflow: hidden;
    object-fit: cover;
    height: 100vh;
}

.full-width img{
	border-radius: 0px;
}


.project-description{
	margin-top: -6px;
	padding-top: 50px;
	padding-bottom: 100px;
	/*color: rgba(255,255,255,1);*/

}





/*  Project Custom Modules */

.project-section-split{
	display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    flex: auto;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.split{
	width: calc(100% / 12 * 6);
    max-width: calc(100% / 12 * 6);
    flex: 0 0 auto;
    flex-basis: calc(100% / 12 * 6);
    background-color: #ffffff;
}



.split-full{
	display: flex;
	flex-direction: row;
    word-wrap: break-word;
    position: relative;
	width: calc(100% / 12 * 6);
    max-width: calc(100% / 12 * 6);
    flex: 0 0 auto;
    flex-basis: calc(100% / 12 * 6);
    padding: 0;
    flex-flow: column wrap;
    height: 100vh;
    overflow: hidden;
    padding: 0;
}

.split-full img{
	border-radius: 0px;
}



.bulletin-identity-nested-image-1{
	padding-top: 25%;
    padding-right: 25%;
    padding-bottom: 25%;
    padding-left: 25%;
    background-color: #fff000;
}

.bulletin-identity-nested-image-2{
	padding-top: 35%;
    padding-right: 15%;
    padding-bottom: 0;
    padding-left: 15%;
    background-color: #ffffff;
}


.nested-image{
	padding-top: 15%;
    padding-right: 25%;
    padding-bottom: 0rem;
    padding-left: 25%;
}





/*  Footer */

.divider {
    background-color: #020202;
    height: 0px;
    margin-bottom: 1.67rem;
    }


.footer{
	padding-top:30px;
    background: #e7e3dc;
	padding-bottom:100px;
}









/*  Project Index */


.thumbnail{
    display: flex;
    text-align: center;
    color: inherit;
    text-decoration: none;
    padding-bottom: 70px;
    transition: 0.15s ease;

}

.thumbnail-card{

}

.thumbnail:hover{
	opacity:.3;
    transition: 0.15s ease;

}

.project-index{
    background: #d7d2c8;
	padding-top:40px;
	/*padding-bottom:30px;*/
	align-items: center;

}








/*  Info Page */


.info-textblock{
	padding-top: 20px;
	padding-bottom: 20px;

}








.link-maria{


}



.info-page{
	padding-top: 30px;
	/*padding-bottom: 100px;*/
	min-height: calc(100vh - 63.5px);

}








/* Typography*/



@font-face {
  font-family: 'Sans';
  src: url('./fonts/NeueHaasGroteskText-55Roman-Web.woff') format('woff'); 
  src: url('./fonts/NeueHaasGroteskText-55Roman-Web.woff2') format('woff2'); 
}

@font-face {
  font-family: 'Serif';
  src: url('./fonts/BradfordLLWeb-Regular.woff') format('woff'); /* Compatible Browser Note */
  src: url('./fonts/BradfordLLWeb-Regular.woff2') format('woff2'); 
}


.subhead{
	padding-bottom: 10px;
}

.subhead-black{
	font-family:'Sans', Helvetica;
	font-size: 15px;
	color: black !important;
	line-height: 15px;
	text-align: left;
	letter-spacing: .5px;
	padding-top: 0px;
	padding-bottom: 10px;
	text-transform: uppercase;
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;
}

.subhead-black-right{
	font-family:'Sans', Helvetica;
	font-size: 15px;
	color: black !important;
	line-height: 15px;
	text-align: right;
	letter-spacing: .5px;
	padding-top: 0px;
	padding-bottom: 10px;
	text-transform: uppercase;
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;
}

.subhead-white{
	font-family:'Sans', Helvetica;
	font-size: 15px;
	color: white !important;
	line-height: 22px;
	text-align: left;
	letter-spacing: .5px;
	padding-top: 0px;
	/*padding-bottom: 100px;*/
	text-transform: ;
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;

}

.subhead-white-title{
	font-family:'Sans', Helvetica;
	font-size: 15px;
	color: white !important;
	line-height: 22px;
	text-align: left;
	letter-spacing: .5px;
	padding-top: 0px;
	/*padding-bottom: 100px;*/
	text-transform: uppercase;
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;

}


.subhead-white a{
	padding-bottom: 1px;
    border-bottom: 1px solid white;
	-webkit-transition: all .2s linear;
	opacity: 1;
	color: white;


}

.subhead-white a:hover{
	-webkit-transition: all .2s linear;
	opacity: .3;

}

.subhead-black a{
	padding-bottom: 1px;
	border-bottom: 1px solid black;
	-webkit-transition: all .2s linear;
	opacity: 1;
	color: black;

}

.subhead-black a:hover{

	-webkit-transition: all .2s linear;
	opacity: .3;

}

.subhead-white-title a{
	padding-bottom: 1px;
    border-bottom: 1px solid white;
	-webkit-transition: all .2s linear;
	opacity: 1;
	color: white;
	text-transform: none;
}

.subhead-white-title a:hover{
	-webkit-transition: all .2s linear;
	opacity: .3;
}

.footer-black a{
	font-family:'Sans', Helvetica;
	font-size: 15px;
	font-color: black !important;
	line-height: 24px;
	text-align: left;
	letter-spacing: .5px;
	padding-top: 0px;
	padding-bottom: 10px;
	text-transform: ;
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;
	padding-bottom: 1px;
	/*border-bottom: 1px solid black;*/
	-webkit-transition: all .2s linear;
	opacity: 1;
	color: black;

}

.footer-black a:hover{

	-webkit-transition: all .2s linear;
	opacity: .3;

}

.body-text{
	font-family:'Serif', Times;
	font-size: 16px;

	color: #fff;
	line-height: 25px;
}

a{ text-decoration: none;
	text-decoration: none;
	text-color: rgb (255,255,255,1);
	font-color:#fff;
}



.caption{
	font-family:'Sans', Helvetica;
	font-size: 15px;
	line-height: 15px;
	text-align: left;
	letter-spacing: .5px;
	padding-top: 15px;
	padding-bottom: 0px;
	color: rgba(255,255,255,1);
	text-transform: uppercase;
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;

}


.project-caption{
	font-family:'Serif', Times;
	font-size: 16px;
	line-height: 16px;
	text-align: left;
	letter-spacing: .5px;
	padding-top: 30px;
	padding-bottom: 30px;
	color: rgba(255,255,255,1);
	text-transform: ;
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;

}

.project-caption-black{
	font-family:'Sans', Helvetica;
	font-size: 15px;
	line-height: 15px;
	text-align: center;
	letter-spacing: .5px;
	padding-top: 30px;
	padding-bottom: 30px;
	color: rgba(0,0,0,1);
	text-transform: uppercase;
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;

}


.project-caption-black a{
	padding-bottom: 1px;
	border-bottom: 1px solid black;
	-webkit-transition: all .2s linear;
	opacity: 1;
	color: black;

}

.project-caption-black a:hover{

	-webkit-transition: all .2s linear;
	opacity: .3;

}


.title{
	font-family: 'Serif',Times;
	font-size: 16px;

	font-weight: normal;
    line-height: 18px;
	text-align: left;
	letter-spacing: 0.1px;
	padding-top: 3px;
	padding-bottom: 20px;
	color: rgba(255,255,255,1);
	text-transform: none;


}

.medium{
	font-family: 'Sans', Helvetica;
	font-size: 15px;

	font-weight: normal;
	font-style: ;
    line-height: 18px;
	text-align: left;
	letter-spacing: 0.1px;
	/*padding-top: 3px;
	padding-bottom: 60px;*/
	margin-left: -3px;
	color: rgba(255,255,255,.7);
	text-transform:none;
	/*display:flex;
	border-radius: 9999px;
	border: rgba(255,255,255,.7) solid 1px;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	width: fit-content;
	font-size: 14px;
	margin-bottom: 60px;*/
	display:flex;
	border-radius: 9999px;
	background-color:rgba(255,255,255,.1) ;
	padding: 7px;
	padding-left: 12px;
	padding-right: 12px;
	width: fit-content;
	font-size: 14px;
	margin-bottom: 60px;
	
}


.project-index-client{
	font-family:'Sans', Helvetica;
	font-size: 15px;
	line-height: 15px;
	text-align: left;
	letter-spacing: .5px;
	padding-top: 10px;
	padding-bottom: 0px;
	color: rgba(1,1,1,1);
	text-transform: uppercase;
	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;


}

.project-index-title{
	font-family: 'Serif',Times;
	font-size: 16px;

	font-weight: normal;
    line-height: 18px;
	text-align: left;
	letter-spacing: .05px;
	padding-top: 3px;
	padding-bottom: 5px;
	color: rgba(1,1,1,1);
	text-transform: none;


}

.project-headline{
	font-family: 'Serif',Times;
	font-size: 30px;
	font-weight: normal;
    line-height: 36px;
	text-align: left;
	letter-spacing: 0px;
	/*padding-top: 50px;*/
	padding-bottom: 50px;
	color: rgba(255,255,255,1);
	text-transform: none;

}

.project-headline-black{
	font-family: 'Serif',Times;
	font-size: 36px;
	font-weight: normal;
    line-height: 44px;
	text-align: left;
	letter-spacing: 0px;
	/*padding-top: 50px;*/
	padding-bottom: 50px;
	color: rgba(0,0,0,1);
	text-transform: none;

}

img.mobile-hide{
	display:block;
}


@media screen and (max-width: 40em) {
	
.info-page{
	padding-bottom: 100px;

}

	
	
.subhead-black, 
.subhead-black-right, 
.subhead-white, 
.footer-black, 
.footer-black a, 
.caption, 
.project-caption, 
.project-caption-black, 
.project-index-client, 
.nav a{	
	font-size: 20px;
}

.caption{
	font-size: 20px;
	line-height: 20px;
	padding-bottom: 2px;
	padding-top: 20px;
}

.title{
	font-size: 20px;
	line-height: 22px;
	padding-bottom: 20px;
}

.medium{
	font-size: 20px;
	line-height: 20px;
	padding: 12px;
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 80px;
	/*padding-bottom: 80px;*/
}

.subhead-white-title{
	font-size: 20px;
	line-height: 22px;
}

.body-text{
	font-size: 20px;
	line-height: 28px;
}


.spacer{
  height: 30px;	
  width: 100%;
}
	
.inner-wrapper{
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.project-index{
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.project-index-client{
	font-size: 20px;
	line-height: 24px;
}

.project-index-title{
	font-size: 20px;
	line-height: 24px;
}

.project-headline{

	padding-top: 50px;
	

}

.project-headline-black{

	padding-top: 50px;
}

.project-section {

}

.full-width{
	height: 300px;
}


.split-full{
   	height: 300px;

}
.subhead-black-right{
	text-align: left;
}

.diptych-left{
	padding-left: 15px;
	padding-right: 15px;
}

.diptych-right{
	padding-left: 15px;
	padding-right: 15px;
}


img.mobile-hide{
	display:none;
}


}


